import React from 'react';
import MainCard from 'components/MainCard';
import { Box, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import hotel from '../../../assets/images/image/hotel.png';
import hotelCheckIn from '../../../assets/images/image/hotel-check-in.png';
import { useAppContext } from 'AppContextProvider';

export default function OwnerList() {
  const navigate = useNavigate();
  // const { ownerPortfolio } = useAppContext(); 

  // ownerPortfolio
  // const handleNavigateBooking = () => {
  //   navigate('PropertyList');
  // };
  // const handleNavigatePropertyAgent = () => {
  //   navigate('propertyAgent');
  // };
  const handleNavigateOwner = () => {
    navigate('ownerPropertyList');
  };
  return (
    <Box  sx={{ mt: '20px' }}>
    <MainCard>
    <Grid container spacing={2} sx={{ mt: '20px' }}>
      {/* <Grid item xs={3} md={3}>
        <Box sx={{ cursor: 'pointer' }} >
          <Box sx={{ background: '#c1aee4', height: '150px', borderRadius: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Box sx={{ display: 'flex', alignItem: 'center', height: '150px', pl: '10px' }}>
                  <img src={hotel} alt="" style={{ height: '115px', width: '115px' }}></img>
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <Box sx={{ mt: '80px', textAlign: 'center' }}>
                  <Typography variant="h4" sx={{ color: '#fff' }}>
                    Owner
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid> */}
      <Grid item xs={3} md={3} onClick={handleNavigateOwner}>
        <Box sx={{ cursor: 'pointer' }}>
          <Box sx={{ background: '#ABC8C4', height: '150px', borderRadius: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Box sx={{ display: 'flex', alignItem: 'center', height: '150px', pl: '10px' }}>
                  <img src={hotelCheckIn} alt="" style={{ height: '115px', width: '130px' }}></img>
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <Box sx={{ mt: '80px', textAlign: 'center' }}>
                  <Typography variant="h4" sx={{ color: '#fff' }}>
                    Property
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  </MainCard>
  </Box>
  )
}
